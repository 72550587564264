import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { IoLogOutOutline } from "react-icons/io5";
import { MdLeaderboard } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { FaCalendar } from "react-icons/fa";
import { FaFaceMeh } from "react-icons/fa6";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function AdminPortal({ user, setUser }) {
  const [users, setUsers] = useState([]);
  const [usersGuesses, setUsersGuesses] = useState([]);
  const [name, setName] = useState('');
  const [flippedUser, setFlippedUser] = useState(null);
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);
  const [userId, setUserId] = useState('');

  const [date, setDate] = React.useState({
    startValue: null,
    endValue: null,
    rangeDates: [],
  });

  const handleChange = (d) => {
    const [startValue, endValue, rangeDates] = d;
    setDate((prev) => ({ ...prev, endValue, startValue, rangeDates }));
  };

  const fetchUsers = async () => {
    const response = await axios.get(`${API_ENDPOINT}/users`);
    setUsers(response.data);
    console.log(response.data)
  };

  useEffect(() => {
    // Fetch the list of users when the component mounts
    fetchUsers();
  }, []);


  // Add other state variables as needed
  const navigate = useNavigate();

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const [day, setDay] = useState(currentDay);

  const fetchUsersGuesses = async (usertoken) => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/all_guesses`,
        { headers: {"Authorization" : `Bearer ${usertoken}`} }
      );
      setUsersGuesses(response.data.filter(user=>!user.admin));
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    // Check if the JWT token exists in the cookies
    const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    const user_name = document.cookie.split('; ').find(row => row.startsWith('user_name='));
    const user_id = document.cookie.split('; ').find(row => row.startsWith('user_id='));

    // If the token exists, set the user state
    if (jwtToken) {
      setUser(jwtToken.replace("jwt=", ""));
      setName(decodeURIComponent(user_name.replace("user_name=", "")));
      fetchUsersGuesses(jwtToken.replace("jwt=", ""));
      setUserId(user_id.replace("user_id=", ""));
    }
    else {
        navigate('/log_in');
    }
  }, []);

  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };

  const setCorrect = async (day_, user_id, value) => {
    const response = await axios.post(`${API_ENDPOINT}/set_correct`,
      {'day': day_, 'user_id': user_id, 'value': value},
      { headers: {"Authorization" : `Bearer ${user}`} }
    );
    setUsers(response.data.filter(user=>!user.admin));
  }

  const renderCorrect = (guess, color) => {
    if (guess.correct == null) {
      return <div/>
    }
    else if (guess.correct == 0) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaThumbsDown style={{color:color}} className="creamText" size={50} />
        </div>
       )
    }
    else if (guess.correct == 1) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaThumbsUp style={{color:color}} className="creamText" size={50} />
        </div>
       )
    }
    else if (guess.correct < 1) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaFaceMeh style={{color:color}} className="creamText" size={50} />
        </div>
       )
    }
  }

  // Add other useEffect for fetching other data
  return (
    <div
      onClick={()=>{setFlippedUser(null);}}
      style={
       {
          height:'100%',
          minHeight: '100vh',
          width:'100%',
          alignItems:'center',
          paddingTop: 50,
          backgroundImage: `url("./background.jpeg")`,
          backgroundColor: '#180A09',
          minHeight:users.length * 75 + 520,
       }
    }>
      <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
        <IoLogOutOutline className="creamText" size={50} style={{flexGrow:0}} onClick={()=>{console.log('logout'); clearAllCookies(); navigate('/log_in');}} />
        <h1 className="creamText">Bonjour, {name} !</h1>
        <div style={{height:50, width:50, flexGrow:0}}>
          <MdLeaderboard className="creamText"
            size={50}
            style={{
              position:'absolute',
              opacity: !showLeaderboard ? "1" : "0",
              transition: "all .4s",
              transitionDelay: showLeaderboard?"0s":".4s",
              visibility: !showLeaderboard ? "visible" : "hidden",
            }}
            onClick={()=>{
              fetchUsers();
              setShowLeaderboard(!showLeaderboard)
            }}
          />
          <FaCalendar className="creamText"
            size={50}
            style={{
              position:'absolute',
              opacity: showLeaderboard ? "1" : "0",
              transition: "all .4s",
              transitionDelay: !showLeaderboard?"0s":".4s",
              visibility: showLeaderboard ? "visible" : "hidden",
            }}
            onClick={()=>{setShowLeaderboard(!showLeaderboard)}}
          />
        </div>
      </div>

      <div style={{
          marginTop:50,
          display:'flex',
          width:'100%',
          alignItems:'center',
          flexDirection:'row',
          justifyContent:'center',
          opacity: !showLeaderboard ? "1" : "0",
          transition: "all .4s",
          transitionDelay: showLeaderboard?"0s":".4s",
          visibility: !showLeaderboard ? "visible" : "hidden",
        }}>
        <div style={{height:'100%', backgroundColor:'#FFFDD0', borderRadius:20, width:250, height:70}}>
          <div style={{display:'flex', height:70, marginVertical:10, alignItems:'center', flexDirection:'row', justifyContent:'space-between'}}>
            <IoIosArrowBack color={day>1?'#321D18':'#B7A58A'} size={50} style={{flexGrow:0}} onClick={()=>{if (day>1) {setDay(day - 1)}}}/>
            <p style={{color:'#321D18', fontSize:30}}>Jour {day}</p>
            <IoIosArrowForward  color={day<24?'#321D18':'#B7A58A'} size={50} style={{flexGrow:0}} onClick={()=>{if (day<24) {setDay(day + 1)}}}/>
          </div>
        </div>
      </div>

      <div
        style={{
          display:"flex",
          flexDirection:"row",
          flexWrap: "wrap",
          marginTop: 50,
          justifyContent:"center",
          alignItems:'flex-start',
          opacity: !showLeaderboard ? "1" : "0",
          transition: "all .4s",
          transitionDelay: showLeaderboard?"0s":".4s",
          visibility: !showLeaderboard ? "visible" : "hidden",
        }}
      >
        {}
        {usersGuesses.map((user) => (
          <Flippy
            key={user.id}
            flipOnHover={false} // default false
            flipOnClick={false} // default false
            flipDirection="horizontal" // horizontal or vertical
            isFlipped={flippedUser == user.id}
            // if you pass isFlipped prop component will be controlled component.
            // and other props, which will go to div
            style={{ width: '200px', height: '200px', margin:20}} /// these are optional style, it is not necessary
          >
            <FrontSide
              style={{
                backgroundColor: user.guesses[parseInt(day)]?'#5f3d32':'#FFFDD0',
                borderRadius:20,
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
              onClick={(e)=>{setFlippedUser(user.id); e.stopPropagation();}}
            >
              <p style={{color:(user.guesses[parseInt(day)]?'#FFFDD0':'#321D18')}} className="name">
                  {user.username}
              </p>
              {user.guesses[parseInt(day)]?renderCorrect(user.guesses[parseInt(day)], '#BB8669'):<div/>}
            </FrontSide>
            <BackSide
              style={{
                borderRadius:20,
                backgroundColor: '#BB8669',
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
              onClick={(e)=>{e.stopPropagation();}}
            >
                <p className="name" style={{fontSize:14, marginBottom:10}}>
                  {"Proposition de " + user.username + " : "}
                </p>
                <p className="name" style={{fontSize:20, marginBottom:10}}>
                  <b>{user.guesses[parseInt(day)]?user.guesses[parseInt(day)].guess:''}</b>
                </p>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around', width:'100%'}}>
                  <button style={{margin:10, flexGrow:1}} onClick={()=>{setCorrect(day, user.id, 0.)}}><FaThumbsDown className="creamText" size={30} /></button>
                  <button style={{margin:10, flexGrow:1}} onClick={()=>{setCorrect(day, user.id, 0.5)}}><FaFaceMeh className="creamText" size={30} /></button>
                  <button style={{margin:10, flexGrow:1}} onClick={()=>{setCorrect(day, user.id, 1.)}}><FaThumbsUp className="creamText" size={30} /></button>
                </div>
              {user.guesses[parseInt(day)]?renderCorrect(user.guesses[parseInt(day)], '#FFFDD0'):<div/>}
            </BackSide>
          </Flippy>
        ))}
      </div>

      <div
        style={{
          display:"flex",
          flexDirection:"column",
          marginTop: 50,
          width:'100%',
          position:'absolute',
          top:100,
          padding:50,
          justifyContent:"center",
          alignItems:"center",
          opacity: !showLeaderboard ? "0" : "1",
          transition: "all .4s",
          transitionDelay: showLeaderboard?".4s":"0s",
          visibility: !showLeaderboard ? "hidden" : "visible",
        }}
      >
        {users.filter((u)=>{return !u[2]}).map((u, i)=> {
          return (
            <div style={{
                borderRadius:20,
                width:'50%',
                minWidth:400,
                padding:20,
                marginBottom:20,
                backgroundColor: u[0]==userId?'#FFFDD0':'#B7A58A',
                flexDirection:"row",
                display:"flex",
                alignContent:"center",
                justifyContent:"space-between"
              }}
            >
              <p className="name" style={{color:'#321D18'}}>
                  <b>{u[4]}</b>
              </p>
              <p className="name" style={{color:'#321D18'}}>
                  {u[1]}
              </p>
              <p className="name" style={{color:'#321D18'}}>
                  {u[3]} pts
              </p>
            </div>
          )}
        )}
      </div>
    </div>
  );
}

export default AdminPortal;
