import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './Input.css';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      value: ""
    };
  }

  handleBlur() {
    if (this.state.value == "") {
      this.setState({focus: false});
    }
  }

  handleChange(event) {
    var value = event.target.value;

    var stateObj = {
      value: value,
      focus: false
    };

    if (value != "") {
      stateObj.focus = true;
    }

    this.setState(stateObj);
  }

  handleFocus() {
    this.setState({focus: true});
  }

  render() {
    var className = "input-container";

    if (this.props.className) {
      className += this.props.className;
    }

    var labelClass = "";
    if (this.state.focus) {
      labelClass = "focused";
    }

    return (
      <div className={className}
        onFocus={this.handleFocus.bind(this)}
        onBlur={this.handleBlur.bind(this)}>
        <label className={labelClass}>
          {this.props.placeholder}
        </label>
        <input name={this.props.name}
          onChange={this.handleChange.bind(this)} />
      </div>);
  }
}

export { Input };