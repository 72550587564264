import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import { useNavigate } from 'react-router-dom';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { IoLogOutOutline } from "react-icons/io5";
import { MdLeaderboard } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import AdminPortal from './AdminPortal';
import { FaCalendar } from "react-icons/fa";
import { FaFaceMeh } from "react-icons/fa6";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function Dashboard({ user, setUser }) {
  const [guesses, setGuesses] = useState([...Array(24).keys()].map((i) => {return {day:i+1}}));
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [admin, setAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [flippedGuess, setFlippedGuess] = useState(null);
  const [guessString, setGuessString] = useState('');
  const [, updateState] = React.useState();
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);

  // Add other state variables as needed
  const navigate = useNavigate();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;

  const processGuesses = (response) => {
    const guesses_temp = guesses;
    for (const guess in response.data.guesses) {
      guesses_temp[response.data.guesses[guess].day - 1] = response.data.guesses[guess];
    }
    setGuesses(guesses_temp);
    forceUpdate();
    if (response.data.user_name) {setName(response.data.user_name);}
  }

  const fetchUsers = async () => {
    const response = await axios.get(`${API_ENDPOINT}/users`);
    setUsers(response.data);
    console.log(response.data)
  };

  useEffect(() => {
    // Fetch the list of users when the component mounts
    fetchUsers();
  }, []);

  const fetchGuesses = async (usertoken) => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/get`,
        { headers: {"Authorization" : `Bearer ${usertoken}`} }
      );
      processGuesses(response);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    // Check if the JWT token exists in the cookies
    const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwt='));
    const user_name = document.cookie.split('; ').find(row => row.startsWith('user_name='));
    const user_id = document.cookie.split('; ').find(row => row.startsWith('user_id='));
    const admin_cookie = document.cookie.split('; ').find(row => row.startsWith('admin='));

    // If the token exists, set the user state
    if (jwtToken) {
      setUser(jwtToken.replace("jwt=", ""));
      setName(decodeURIComponent(user_name.replace("user_name=", "")));
      setUserId(user_id.replace("user_id=", ""));
      fetchGuesses(jwtToken.replace("jwt=", ""));
      if (admin_cookie) {
        setAdmin(admin_cookie.replace("admin=", ""));
      }
    }
    else {
        navigate('/log_in');
    }
  }, []);

  const clearAllCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };

  const renderBackside = (guess) => {
     if (currentMonth!=12 || currentDay < guess.day) {return (
        <p className="name" style={{fontSize:18}}>
            {"Pas si vite, gourmand !"}
        </p>
      )
     }
     else if (guess.correct != null) {return (
        <div style={{
          flexDirection:"column",
          display:"flex",
          alignContent:"center",
          justifyContent:"center"
        }}>
          <p className="name" style={{fontSize:18, marginBottom:10}}>
              {guess.guess?"Ta proposition : ":"Tu n'as pas fait de proposition... Trop tard !"} <br/>
              <b>{guess.guess?guess.guess:" "}</b>
          </p>
          <p className="name" style={{fontSize:18, marginBottom:10}}>
            {guess.guess?(guess.correct>0?(guess.correct==1?"Bien joué !":"Presque..."):"Raté !"):""}
          </p>
        </div>
      )
     }
     else {
     return(
     <div style={{
        flexDirection:"column",
        display:"flex",
        alignContent:"center",
        justifyContent:"center"
      }}>
        <p className="name" style={{fontSize:14, marginBottom:10}}>
            {guess.guess?"Ta proposition : ":"Devine le goût du jour "+guess.day+":"} <br/>
            <b>{guess.guess?guess.guess:" "}</b>
        </p>
        <input
          onChange={(value)=>{setGuessString(value.target.value)}}
          value={guessString}
          type="text"
          placeholder={guess.guess?"Changer de proposition":"Proposition"} />
        <button className='cardButton' onClick={(e)=>{
          axios.post(`${API_ENDPOINT}/guess`, {
            day: flippedGuess,
            guess: guessString,
          },
          { headers: {"Authorization" : `Bearer ${user}`} }
          ).then((response)=>{
            processGuesses(response)
          });
          e.stopPropagation();
        }}>Soumettre</button>
      </div>
    )}}

  const renderCorrect = (guess) => {
    if (guess.correct == null) {
      return <div/>
    }
    else if (guess.correct == 0) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaThumbsDown className="creamText" size={50} />
        </div>
       )
    }
    else if (guess.correct == 1) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaThumbsUp className="creamText" size={50} />
        </div>
       )
    }
    else if (guess.correct < 1) {
      return (
        <div style={{position:'absolute', top:-20, right:-20, width:60, height:60}}>
          <FaFaceMeh className="creamText" size={50} />
        </div>
       )
    }
  }

  if (admin) {
    return (
      <AdminPortal user={user} setUser={setUser}/>
    )
  }

  // Add other useEffect for fetching other data
  return (
    <div
      onClick={()=>{setFlippedGuess(null); setGuessString('')}}
      style={
       {
          height:'100%',
          minHeight: '100vh',
          width:'100%',
          paddingTop: 50,
          backgroundImage: `url("./background.jpeg")`,
          backgroundColor: '#180A09',
          minHeight:users.length * 75 + 520,
       }
    }>
      <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
        <IoLogOutOutline className="creamText" size={50} style={{flexGrow:0}} onClick={()=>{console.log('logout'); clearAllCookies(); navigate('/log_in');}} />
        <h1 className="creamText">Bonjour, {name} !</h1>
        <div style={{height:50, width:50, flexGrow:0}}>
          <MdLeaderboard className="creamText"
            size={50}
            style={{
              position:'absolute',
              opacity: !showLeaderboard ? "1" : "0",
              transition: "all .4s",
              transitionDelay: showLeaderboard?"0s":".4s",
              visibility: !showLeaderboard ? "visible" : "hidden",
            }}
            onClick={()=>{
              fetchUsers();
              setShowLeaderboard(!showLeaderboard)
            }}
          />
          <FaCalendar className="creamText"
            size={50}
            style={{
              position:'absolute',
              opacity: showLeaderboard ? "1" : "0",
              transition: "all .4s",
              transitionDelay: !showLeaderboard?"0s":".4s",
              visibility: showLeaderboard ? "visible" : "hidden",
            }}
            onClick={()=>{setShowLeaderboard(!showLeaderboard)}}
          />
        </div>
      </div>

      <div
        style={{
          display:"flex",
          flexDirection:"row",
          flexWrap: "wrap",
          marginTop: 50,
          alignItems:'flex-start',
          justifyContent:"center",
          opacity: !showLeaderboard ? "1" : "0",
          transition: "all .4s",
          transitionDelay: showLeaderboard?"0s":".4s",
          visibility: !showLeaderboard ? "visible" : "hidden",
        }}
      >
        {guesses.map((guess) => (

          <Flippy
            key={guess.day}
            flipOnHover={false} // default false
            flipOnClick={false} // default false
            flipDirection="horizontal" // horizontal or vertical
            isFlipped={flippedGuess == guess.day}
            // if you pass isFlipped prop component will be controlled component.
            // and other props, which will go to div
            style={{ width: '200px', height: '200px', margin:20}} /// these are optional style, it is not necessary
          >
            <FrontSide
              style={{
                backgroundColor: guess.guess?'#5f3d32':((currentMonth==12 && currentDay == guess.day)?'#FFFDD0':'#B7A58A'),
                borderRadius:20,
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
              onClick={(e)=>{setFlippedGuess(guess.day); setGuessString(''); e.stopPropagation();}}
            >
              <p className="name" style={{color:guess.guess?'#FFFDD0':'#321D18'}}>
                  {guess.day}
              </p>
              {renderCorrect(guess)}

            </FrontSide>
            <BackSide
              style={{
                borderRadius:20,
                backgroundColor: '#BB8669',
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
              onClick={(e)=>{e.stopPropagation();}}
            >
              {renderBackside(guess)}
              {renderCorrect(guess)}

            </BackSide>
          </Flippy>
        ))}
      </div>

      <div
        style={{
          display:"flex",
          flexDirection:"column",
          marginTop: 50,
          width:'100%',
          position:'absolute',
          top:100,
          padding:50,
          justifyContent:"center",
          alignItems:"center",
          opacity: !showLeaderboard ? "0" : "1",
          transition: "all .4s",
          transitionDelay: showLeaderboard?".4s":"0s",
          visibility: !showLeaderboard ? "hidden" : "visible",
        }}
      >
        <div>
          {users.filter((u)=>{return !u[2]}).map((u, i)=> {
            return (
              <div style={{
                  borderRadius:20,
                  width:'50%',
                  minWidth:400,
                  padding:20,
                  marginBottom:20,
                  backgroundColor: u[0]==userId?'#FFFDD0':'#B7A58A',
                  flexDirection:"row",
                  display:"flex",
                  alignContent:"center",
                  justifyContent:"space-between"
                }}
              >
                <p className="name" style={{color:'#321D18'}}>
                    <b>{u[4]}</b>
                </p>
                <p className="name" style={{color:'#321D18'}}>
                    {u[1]}
                </p>
                <p className="name" style={{color:'#321D18'}}>
                    {u[3]} pts
                </p>
              </div>
            )}
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
