import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './Signup.css';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import {Input} from './components/Input.js'
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function Signup({ setUser }) {
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [users, setUsers] = useState([]);
  const [flippedUser, setFlippedUser] = useState(null);
  const [cookies, setCookie] = useCookies(['jwt']);

  useEffect(() => {
    // Fetch the list of users when the component mounts
    const fetchUsers = async () => {
        const response = await axios.get(`${API_ENDPOINT}/users`);
        setUsers(response.data);
    };

    fetchUsers();
  }, []);

  const decodeHtmlCharCodes = str => {
      const decodedString = document.createElement("textarea");
      decodedString.innerHTML = str;
      return decodedString.value;
  }

  useEffect(() => {
    // Check if the JWT token exists in the cookies
    const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwt='));

    // If the token exists, set the user state
    if (jwtToken) {
      console.log(jwtToken)
      setUser(jwtToken);
      console.log('user token found : ', jwtToken)
      navigate('/');
    }
  }, []);

  const handleUserClick = (user) => {
    // Flip the selected user
    setFlippedUser(user);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_ENDPOINT}/login`, {
        user_id: flippedUser,
        code: code,
      });
      console.log(response)
      // Store the JWT token in a cookie that never expires
      setCookie('jwt', response.data.token, { path: '/', expires: new Date(253402300000000) });
      setCookie('user_name', decodeHtmlCharCodes(response.data.user_name), { path: '/', expires: new Date(253402300000000) });
      setCookie('user_id', response.data.user_id, { path: '/', expires: new Date(253402300000000) });
      if (response.data.admin) {
        setCookie('admin', true, { path: '/', expires: new Date(253402300000000) });
      }

      // Reset state
      setFlippedUser(null);
      navigate('/');

    } catch (error) {
      // Handle login error
    }
  };

  return (
    <div style={
       {
          height:'100%',
          minHeight: '100vh',
          width:'100%',
          paddingTop: 50,
          backgroundImage: `url("./background.jpeg")`,
          backgroundColor: '#180A09',
       }
    }>

      <h1 className="creamText">Qui va là ?</h1>

      <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap", marginTop: 50, justifyContent:"center"}}>
        {users.map((user) => (
          <Flippy
            key={user[0]}
            flipOnHover={false} // default false
            flipOnClick={false} // default false
            flipDirection="horizontal" // horizontal or vertical
            isFlipped={flippedUser == user[0]}
            // if you pass isFlipped prop component will be controlled component.
            // and other props, which will go to div
            style={{ width: '200px', height: '200px', margin:20}} /// these are optional style, it is not necessary
          >
            <FrontSide
              style={{
                backgroundColor: '#FFFDD0',
                borderRadius:20,
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
              onClick={()=>{setFlippedUser(user[0])}}
            >
              <p className="name">
                  {user[1]}
              </p>
            </FrontSide>
            <BackSide
              style={{
                borderRadius:20,
                backgroundColor: '#BB8669',
                flexDirection:"column",
                display:"flex",
                alignContent:"center",
                justifyContent:"center"
              }}
            >
	      <input
		  onChange={(value) => {
		    const uppercaseCode = value.target.value
		      .normalize('NFD') // Normalize to decompose accented characters
		      .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
		      .toUpperCase() // Convert to uppercase
		      .replace(/[^A-Z]/g, ''); // Remove non A-Z characters
		    setCode(uppercaseCode); // Update the state with the filtered code
		  }}
		  value={code}
		  type="text"
		  placeholder="Code d'accès"
		/>
              <button onClick={handleLogin}>Connexion</button>
            </BackSide>
          </Flippy>
        ))}
      </div>
    </div>
  );
}

export default Signup;
