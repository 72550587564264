import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Signup from './Signup';
import Dashboard from './Dashboard';
import './App.css';


function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const htmlElement = document.querySelector('html');
    htmlElement.setAttribute('translate', 'no');
  }, []);

  return (
    <Router>
      <Routes>
          <Route path="/" element={<Dashboard user={user} setUser={setUser} />} />
          <Route path="/log_in" element={<Signup setUser={setUser} />} />
      </Routes>
    </Router>
  );
}

export default App;